import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";

import config from "src/common/config";

class Firebase {
  _app?: FirebaseApp;
  _analytics?: Analytics;

  constructor() {
    if (config.firebase) {
      this._app = initializeApp(config.firebase);
      this._analytics = getAnalytics(this._app);
      console.log("this._analytics", this._analytics);
    } else {
      console.error("No firebase configuration set");
    }
  }

  logAnalyticsEvent(eventName: string) {
    if (this._analytics) {
      logEvent(this._analytics, eventName);
    }
  }
}

export default new Firebase();
