type AppConfig = {
  apiUrl: string;
  gaMeasurementId: string | null;
  launchDarklyKey: string;
  pusher: {
    key: string;
    cluster: string;
  };
  firebase: {
    apiKey: string;
    projectId: string;
    appId: string;
  };
};

declare global {
  interface Window {
    __config: AppConfig;
  }
}

export default window.__config || {
  apiUrl: "http://localhost:9000",
  gaMeasurementId: null,
  launchDarklyKey: "6303a50854e511124387c35c",
  pusher: {
    key: "d2f27160a9189f0103f9",
    cluster: "us2",
  },
  firebase: {
    apiKey: "AIzaSyCwTjeB9yzDcbAiRaRUXsKDwQAblW7Q5MI",
    projectId: "gatehawk-intercom-dev",
    appId: "1:460429714952:web:919802bf6eea035f4fd3bd",
  },
};
