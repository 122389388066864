import React from "react";

const EndCall = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.26"
      height="21.26"
      viewBox="0 0 21.26 21.26"
    >
      <g id="Group_172" data-name="Group 172" transform="translate(0 0)">
        <path
          id="Icon_awesome-phone"
          data-name="Icon awesome-phone"
          d="M14.487.723l-3.054-.7a.709.709,0,0,0-.807.408L9.217,3.714a.7.7,0,0,0,.2.822L11.2,5.993A10.882,10.882,0,0,1,6,11.2L4.54,9.416a.7.7,0,0,0-.822-.2L.429,10.623a.713.713,0,0,0-.411.81l.7,3.054a.7.7,0,0,0,.687.546A13.622,13.622,0,0,0,15.033,1.41.7.7,0,0,0,14.487.723Z"
          transform="translate(10.63 21.26) rotate(-135)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default EndCall;
