import React from "react";

const AccessDenied = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="59.227"
      height="59.227"
      viewBox="0 0 59.227 59.227"
    >
      <path
        id="Icon_metro-cross"
        data-name="Icon metro-cross"
        d="M61.257,49.509h0L43.289,31.541,61.256,13.574h0a1.855,1.855,0,0,0,0-2.617L52.769,2.469a1.856,1.856,0,0,0-2.617,0h0L32.184,20.436,14.217,2.469h0a1.855,1.855,0,0,0-2.617,0L3.112,10.956a1.855,1.855,0,0,0,0,2.617h0L21.079,31.541,3.112,49.509h0a1.855,1.855,0,0,0,0,2.617L11.6,60.614a1.855,1.855,0,0,0,2.617,0h0L32.184,42.646,50.152,60.614h0a1.855,1.855,0,0,0,2.617,0l8.488-8.488a1.855,1.855,0,0,0,0-2.617Z"
        transform="translate(-2.571 -1.928)"
        fill="#fff"
      />
    </svg>
  );
};

export default AccessDenied;
