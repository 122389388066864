import ky, { HTTPError } from "ky";

import config from "src/common/config";

const kyInstance = ky.create({
  prefixUrl: config.apiUrl,
});

export { HTTPError };
export default kyInstance;
