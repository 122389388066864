import React from "react";

const AccessGranted = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="79.423"
      height="59.227"
      viewBox="0 0 79.423 59.227"
    >
      <path
        id="Icon_awesome-check"
        data-name="Icon awesome-check"
        d="M26.975,62.641,1.163,36.828a3.971,3.971,0,0,1,0-5.616L6.779,25.6a3.971,3.971,0,0,1,5.616,0L29.784,42.984,67.028,5.74a3.971,3.971,0,0,1,5.616,0l5.616,5.616a3.971,3.971,0,0,1,0,5.616L32.592,62.641A3.971,3.971,0,0,1,26.975,62.641Z"
        transform="translate(0 -4.577)"
        fill="#fff"
      />
    </svg>
  );
};

export default AccessGranted;
