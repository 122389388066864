import Pusher from "pusher-js";

import config from "src/common/config";

export type PusherAuthResponse = {
  auth: string;
  channel_data?: string;
  shared_secret?: string;
};

const pusher = new Pusher(config.pusher.key, {
  cluster: config.pusher.cluster,
  encrypted: true,
  disableStats: true,
  authEndpoint: "",
});

Pusher.logToConsole = true;

export default pusher;
