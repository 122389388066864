import React, { useState } from "react";
import { VStack } from "@chakra-ui/react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { FeatureFlags, Resident } from "src/common/types";
import ResidentDirectory from "src/routes/intercom/ResidentDirectory";
import VisitorCall from "src/routes/intercom/VisitorCall";
import { AuthProvider } from "src/contexts/VisitorContext";
import AuthGuard from "src/routes/intercom/AuthGuard";
import { CallProvider } from "src/contexts/CallContext";

export const MobileIntercom = () => {
  const [resident, setResident] = useState<Resident | null>(null);
  const [isManagerCall, setIsManagerCall] = useState(false);
  const { defaultMobileIntercom }: FeatureFlags = useFlags();

  if (!defaultMobileIntercom) {
    return null;
  }

  return (
    <AuthProvider>
      <AuthGuard>
        <CallProvider
          onReset={() => {
            setResident(null);
            setIsManagerCall(false);
          }}
        >
          <VStack
            maxW="2xl"
            h="100vh"
            alignItems="flex-start"
            paddingX={4}
            paddingY={4}
            spacing={8}
          >
            {resident || isManagerCall ? (
              <VisitorCall
                resident={resident}
                onCallEnded={() => {
                  setResident(null);
                  setIsManagerCall(false);
                }}
              />
            ) : (
              <ResidentDirectory
                onResidentSelected={(resident) => {
                  if (resident === null) {
                    setIsManagerCall(true);
                  }
                  setResident(resident);
                }}
              />
            )}
          </VStack>
        </CallProvider>
      </AuthGuard>
    </AuthProvider>
  );
};
