import React from "react";
import { withLDProvider } from "launchdarkly-react-client-sdk";

import { MobileIntercom } from "src/routes/intercom/MobileIntercom";
import config from "src/common/config";

function App() {
  return <MobileIntercom />;
}

export default withLDProvider({
  clientSideID: config.launchDarklyKey,
  options: {
    streaming: false,
  },
})(App);
