import React from "react";
import type { FC, ReactNode } from "react";

import useAuth from "src/hooks/useAuth";
import LocationCheck from "src/routes/intercom/LocationCheck";

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const { isAuthenticated, resetLocation, errorMessage, isFetching } =
    useAuth();

  if (!isAuthenticated) {
    return (
      <LocationCheck
        onCheckLocation={async () => {
          await resetLocation();
        }}
        errorMessage={errorMessage}
        isChecking={isFetching}
      />
    );
  }

  return <>{children}</>;
};

export default AuthGuard;
