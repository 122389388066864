import React from "react";

const GateSearch = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="118.599"
      height="89.139"
      viewBox="0 0 125 96"
    >
      <g
        id="Group_311"
        data-name="Group 311"
        transform="translate(-153.596 -200.528)"
      >
        <g id="gate" transform="translate(153.596 167.239)">
          <path
            id="Path_348"
            data-name="Path 348"
            d="M14.556,91.058l24.422-9.7V33.29H34.126c-5.046,0-8.962,2.821-12.416,5.31-2.384,1.717-4.667,3.361-7.154,4.052V33.29H0v63.4H14.556Zm0-5.221v-5.23l19.57-7.776v5.23Zm9.99-43.3c3.137-2.26,6.1-4.394,9.58-4.394V67.61l-19.57,7.776V47.625C18.465,46.915,21.666,44.611,24.546,42.536ZM9.7,91.837H4.852v-53.7H9.7Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            id="Path_349"
            data-name="Path 349"
            d="M300.274,33.29h-4.852v9.362c-2.487-.691-4.77-2.335-7.154-4.052-3.454-2.489-7.37-5.31-12.416-5.31H271V81.354l24.422,9.7v5.631h14.556V33.29h-9.7Zm-14.842,9.246c2.88,2.075,6.081,4.379,9.99,5.089V75.386l-19.57-7.776V38.142c3.48,0,6.443,2.135,9.58,4.394Zm-9.58,35.525v-5.23l19.57,7.776v5.23Zm29.274,13.777h-4.852v-53.7h4.852Z"
            transform="translate(-227.17)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_308"
          data-name="Group 308"
          transform="matrix(0.998, 0.07, -0.07, 0.998, -15495.09, -7776.784)"
        >
          <circle
            id="Ellipse_34"
            data-name="Ellipse 34"
            cx="26.419"
            cy="26.419"
            r="26.419"
            transform="translate(16220.302 6885.623)"
            fill="#4caf50"
          />
          <path
            id="Ellipse_34_-_Outline"
            data-name="Ellipse 34 - Outline"
            d="M26.419,1a25.426,25.426,0,0,0-9.894,48.84A25.426,25.426,0,0,0,36.313,3a25.259,25.259,0,0,0-9.894-2m0-1A26.419,26.419,0,1,1,0,26.419,26.419,26.419,0,0,1,26.419,0Z"
            transform="translate(16220.302 6885.623)"
            fill="#fff"
          />
          <g
            id="magnifier"
            transform="matrix(0.978, -0.208, 0.208, 0.978, 16207.773, 6890.337)"
          >
            <g id="Group_307" data-name="Group 307" transform="translate(0 0)">
              <path
                id="Path_350"
                data-name="Path 350"
                d="M59.742,53.581l-4.812-4.812a30.511,30.511,0,1,0-6.161,6.161l4.812,4.812ZM48.118,43.259l-4.812,4.812a21.764,21.764,0,0,1-28.2-32.989A21.807,21.807,0,0,1,52.326,30.5,21.536,21.536,0,0,1,48.118,43.259Z"
                fill="#fff"
              />
              <path
                id="Path_350_-_Outline"
                data-name="Path 350 - Outline"
                d="M30.476,61.506A30.983,30.983,0,0,1,8.591,8.591a30.994,30.994,0,0,1,47,40.128L59.772,52.9a.46.46,0,0,1,.173-.026h.334l.128.309a.46.46,0,0,1,.008.36l.034.034-.354.354-5.118,5.118L53.935,60.1h0l-.2.2h0l-.152.152-.03-.03a.459.459,0,0,1-.173.026h-.334l-.128-.309a.463.463,0,0,1-.008-.36l-4.189-4.189a30.98,30.98,0,0,1-18.243,5.919ZM30.51.5A30,30,0,0,0,9.3,51.739a29.85,29.85,0,0,0,21.178,8.767,29.978,29.978,0,0,0,17.993-5.977l.347-.26,4.765,4.765.689-.689,4.765-4.765-4.765-4.765.26-.347A29.986,29.986,0,0,0,30.51.5Zm-.058,52.279A22.289,22.289,0,0,1,8.212,30.5a22.307,22.307,0,0,1,44.613,0,22.04,22.04,0,0,1-4.3,13.058l-.023.031-4.867,4.867-.031.023a22.04,22.04,0,0,1-13.058,4.3Zm.066-43.59a21.3,21.3,0,0,0-15.06,36.367,20.994,20.994,0,0,0,14.994,6.224h.088A21.045,21.045,0,0,0,42.98,47.69l4.757-4.757A21.261,21.261,0,0,0,30.519,9.189Z"
                fill="#fff"
              />
            </g>
            <rect
              id="Rectangle_504"
              data-name="Rectangle 504"
              width="12.329"
              height="17.612"
              transform="matrix(0.719, -0.695, 0.695, 0.719, 50.441, 59.053)"
              fill="#fff"
            />
            <path
              id="Rectangle_504_-_Outline"
              data-name="Rectangle 504 - Outline"
              d="M4,4v9.613H8.329V4H4M0,0H12.329V17.612H0Z"
              transform="matrix(0.719, -0.695, 0.695, 0.719, 50.441, 59.053)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GateSearch;
