import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

import AccessDenied from "src/common/icons/AccessDenied";
import AccessGranted from "src/common/icons/AccessGranted";

/**
 * Simple display that shows the call access status, either denied or not.
 */
export function AccessOrDenied({
  denied,
  error,
}: {
  denied: boolean;
  error: Error | null;
}) {
  return (
    <Box
      borderRadius={6}
      width={304}
      height={191}
      bgColor={denied ? "#FF453A" : "#009B10"}
    >
      <Flex
        gap={4}
        alignItems="center"
        height="100%"
        direction="column"
        justifyContent="center"
      >
        <Text as="strong" fontSize={24}>
          {denied
            ? error
              ? error.message
              : "Access Denied"
            : "Access Granted"}
        </Text>
        {denied ? <AccessDenied /> : <AccessGranted />}
      </Flex>
    </Box>
  );
}
