import { useQuery } from "react-query";
import { HTTPError } from "ky";

import useAuth from "src/hooks/useAuth";
import { VisitorDirectoryResponse } from "src/common/types";

export const useDirectoryData = () => {
  const { authenticatedKy, isAuthenticated } = useAuth();
  return useQuery<VisitorDirectoryResponse, HTTPError>(
    "VISITOR-DIRECTORY",
    () => {
      return authenticatedKy
        .get("telephone-entry/visitor/directory", {})
        .json<VisitorDirectoryResponse>();
    },
    {
      enabled: isAuthenticated,
    }
  );
};
