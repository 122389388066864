import React from "react";
import { Box, Button, Text, VStack } from "@chakra-ui/react";

import GateSearch from "src/common/icons/GateSearch";
import GateNotFound from "src/common/icons/GateNotFound";

interface LocationCheckProps {
  onCheckLocation: () => Promise<void>;
  errorMessage: string | null;
  isChecking?: boolean;
}

const LocationCheck: React.FC<LocationCheckProps> = ({
  onCheckLocation,
  errorMessage,
  isChecking = false,
}) => {
  return (
    <VStack
      height="100vh"
      justifyContent="center"
      gap={3}
      textAlign="center"
      paddingX={6}
    >
      <>
        {errorMessage && !isChecking ? (
          <>
            <GateNotFound />
            <Box>
              <Text as="strong">No entry point found</Text>
              <Text>Try turning location services on.</Text>
            </Box>
          </>
        ) : (
          <>
            <GateSearch />
            <Text fontSize="md">
              {isChecking
                ? "Searching for entry point..."
                : "Click the button below to find your closest entry point. You will be asked for location permission."}
            </Text>
          </>
        )}

        <Button
          sx={{
            color: "black",
          }}
          isDisabled={isChecking}
          onClick={onCheckLocation}
        >
          {errorMessage ? "Retry" : "Locate entry point"}
        </Button>
      </>
    </VStack>
  );
};

export default LocationCheck;
