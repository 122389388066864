import React from "react";

const Search = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.403"
      height="14.406"
      viewBox="0 0 14.403 14.406"
    >
      <path
        id="Icon_awesome-search"
        data-name="Icon awesome-search"
        d="M14.207,12.455l-2.8-2.8a.675.675,0,0,0-.478-.2h-.459a5.849,5.849,0,1,0-1.013,1.013v.459a.675.675,0,0,0,.2.478l2.8,2.8a.672.672,0,0,0,.954,0l.8-.8a.678.678,0,0,0,0-.957Zm-8.356-3a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,5.852,9.453Z"
        fill="#939393"
        opacity="0.665"
      />
    </svg>
  );
};

export default Search;
