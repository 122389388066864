import React, { FC, ReactNode, useCallback, useEffect } from "react";
import { createContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { TelnyxRTC } from "@telnyx/webrtc";
import { useMutation } from "react-query";

import { ringingSound } from "src/common/sounds";
import pusher from "src/common/pusher";
import {
  CallState,
  CreateCallRequest,
  CreateCallResponse,
  InitiateSIPCallRequest,
  InitiateSIPCallResponse,
  Resident,
  SIPCall,
  SIPMessage,
  TelnyxNotification,
} from "src/common/types";
import { HTTPError } from "src/common/ky";
import useAuth from "src/hooks/useAuth";
import firebase from "src/common/firebase";

interface CallProviderProps {
  children?: ReactNode;
  onReset: () => void;
}

interface CallContextProps {
  activeCall: SIPCall | null;
  callState: CallState;
  endCall: () => Promise<void>;
  error: Error | null;
  intercomCallId: string | null;
  isCalling: boolean;
  isReady: boolean;
  pusherChannelName: string | null;
  requestedEnd: boolean;
  remoteStream: MediaStream | null;
  resident: Resident | null;
  sipUsername: string | null;
  startCall: (resident: Resident | null) => Promise<void>;
  wasOpened: boolean;
}

const initialState: CallContextProps = {
  activeCall: null,
  callState: "calling",
  endCall: async () => {
    return;
  },
  error: null,
  intercomCallId: null,
  isCalling: false,
  isReady: false,
  pusherChannelName: null,
  requestedEnd: false,
  remoteStream: null,
  resident: null,
  sipUsername: null,
  startCall: async () => {
    return;
  },
  wasOpened: false,
};

const CallContext = createContext<CallContextProps>(initialState);

// The time after access granted or denied to refresh the page
const REFRESH_AFTER_ACCESS_MS = 5000;

export const CallProvider: FC<CallProviderProps> = (props) => {
  const { children, onReset } = props;
  const { authenticatedKy } = useAuth();
  const [telnyxClient, setTelnyxClient] = useState<TelnyxRTC | null>(null);
  const [state, setState] =
    useState<Omit<CallContextProps, "telnyxClient" | "startCall">>(
      initialState
    );

  const initiateSipCallMutation = useMutation<
    InitiateSIPCallResponse,
    HTTPError,
    InitiateSIPCallRequest
  >(async (values) => {
    return authenticatedKy
      .post("telephone-entry/visitor/sip-call", {
        json: values,
      })
      .json<InitiateSIPCallResponse>();
  }, {});

  const createCallMutation = useMutation<
    CreateCallResponse,
    HTTPError,
    CreateCallRequest
  >(async (values) => {
    return authenticatedKy
      .post("telephone-entry/visitor/calls", {
        json: values,
      })
      .json<CreateCallResponse>();
  }, {});

  const startCall = useCallback(
    async (resident: Resident | null) => {
      if (!telnyxClient) {
        const json = await createCallMutation.mutateAsync({
          tenantId: resident?.id || null,
        });

        const pusherChannelName = `private-dmi-${uuidv4()}`;
        const intercomChannel = pusher.subscribe(pusherChannelName);

        intercomChannel.bind("pusher:subscription_error", () => {
          setTimeout(() => {
            intercomChannel.subscribe();
          }, 5000);
        });

        intercomChannel.bind("sip-message", (msg: SIPMessage) => {
          if (msg.room === json.intercomCallId) {
            if (msg.connected) {
              setState((state) => ({
                ...state,
                callState: "connected",
              }));
              ringingSound.stop();
            } else if (msg.open) {
              setState((state) => ({
                ...state,
                wasOpened: true,
                activeCall: null,
              }));
              console.info("[Firebase] Access Granted");
              firebase.logAnalyticsEvent("dmi_sip_call_access_granted");
              setTimeout(() => {
                window.location.href = "/directory";
              }, REFRESH_AFTER_ACCESS_MS);
            }
          }
        });

        ringingSound.play();

        setState((state) => ({
          ...state,
          resident,
          pusherChannelName,
          intercomCallId: json.intercomCallId,
          sipUsername: json.sip_username,
        }));

        const session = new TelnyxRTC({
          login_token: "",
          login: json.sip_username,
          password: json.sip_password,
        });

        session.on("telnyx.error", () => {
          ringingSound.stop();
          setTimeout(() => {
            // Send the user back to the directory listing
            onReset();
            setState(initialState);
          }, REFRESH_AFTER_ACCESS_MS);
          setState((state) => {
            return {
              ...state,
              callState: "destroyed",
              activeCall: null,
              error: new Error("Error, please try again."),
            };
          });

          session?.disconnect();
        });

        session.on("telnyx.socket.error", () => {
          session?.disconnect();
        });

        session.on(
          "telnyx.notification",
          (notification: TelnyxNotification) => {
            console.log(notification);
            if (notification.type === "callUpdate") {
              const call = notification.call;

              if (call.state === "ringing") {
                call.answer();
              }

              setState((state) => {
                const newState = {
                  ...state,
                };
                if (call.state === "destroy") {
                  ringingSound.stop();
                  newState.callState = "destroyed";
                  newState.activeCall = null;
                  if (!state.wasOpened) {
                    console.info("[Firebase] Access Denied");
                    firebase.logAnalyticsEvent("dmi_sip_call_access_denied");
                    setTimeout(() => {
                      // Send the user back to the directory listing
                      onReset();
                      setState(initialState);
                    }, REFRESH_AFTER_ACCESS_MS);
                  }
                }
                if (call.state === "active") {
                  newState.activeCall = call;
                  newState.remoteStream = call.remoteStream;
                }

                return newState;
              });
            }
          }
        );

        session.on("telnyx.ready", () => {
          setState((state) => ({
            ...state,
            isReady: true,
          }));
        });
        session?.connect();
        setTelnyxClient(session);
      }
    },
    [createCallMutation.mutateAsync]
  );

  useEffect(() => {
    (async () => {
      if (
        state.isReady &&
        state.intercomCallId &&
        state.pusherChannelName &&
        state.sipUsername &&
        !state.isCalling
      ) {
        const params = {
          intercomCallId: state.intercomCallId,
          pusherChannelName: state.pusherChannelName,
          tenantId: state.resident?.id || null,
          username: `sip:${state.sipUsername}@sip.telnyx.com`,
        };

        // Make call
        await initiateSipCallMutation.mutateAsync(params);

        setState((state) => ({
          ...state,
          isCalling: true,
        }));
      }
    })();
  }, [state, initiateSipCallMutation.mutateAsync]);

  const endCall = useCallback(async () => {
    ringingSound.stop();

    if (state.activeCall) {
      await state.activeCall.hangup();

      if (telnyxClient) {
        telnyxClient.off("telnyx.ready");
        telnyxClient.off("telnyx.notification");
        telnyxClient.off("telnyx.error");
        await telnyxClient.disconnect();
      }

      setState((state) => {
        return {
          ...initialState,
          callState:
            // Keep state only if we were not already connected
            state.callState === "connected" ? "calling" : state.callState,
        };
      });
    }
    return;
  }, [state.activeCall, telnyxClient]);

  useEffect(() => {
    (async () => {
      if (state.requestedEnd) {
        await endCall();
        setState((state) => ({
          ...state,
          requestedEnd: false,
        }));
      }
    })();
  }, [state.requestedEnd, endCall]);

  return (
    <CallContext.Provider value={{ ...state, startCall, endCall }}>
      {children}
    </CallContext.Provider>
  );
};

export const CallConsumer = CallContext.Consumer;

export default CallContext;
