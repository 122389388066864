import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import ReactGA from "react-ga4";
import { QueryClient, QueryClientProvider } from "react-query";

import App from "src/App";
import reportWebVitals from "src/reportWebVitals";
import theme from "src/common/theme";
import config from "src/common/config";
import { HTTPError } from "src/common/ky";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

if (config.gaMeasurementId) {
  ReactGA.initialize(config.gaMeasurementId);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      useErrorBoundary: (error) => {
        return error instanceof HTTPError && error.response.status >= 500;
      },
    },
    mutations: {
      useErrorBoundary: (error) => {
        return error instanceof HTTPError && error.response.status >= 500;
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
