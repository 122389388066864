import React, { FC, useState } from "react";
import {
  Box,
  Button,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Spinner,
  Text,
} from "@chakra-ui/react";

import { Resident } from "src/common/types";
import { useDirectoryData } from "src/hooks/useDirectoryData";
import Search from "src/common/icons/Search";

interface ResidentDirectoryProps {
  onResidentSelected: (resident: Resident | null) => void;
}

const ResidentDirectory: FC<ResidentDirectoryProps> = ({
  onResidentSelected,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: directoryData } = useDirectoryData();
  if (!directoryData) {
    return <Spinner color="brand.white" />;
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={4}
      alignItems="flex-start"
      width="100%"
      pt={10}
    >
      <Box display="flex" width="100%" gap={1}>
        <InputGroup flexGrow={1}>
          <Input
            placeholder="Search"
            sx={{
              bgColor: "#333333",
              border: "none",
            }}
            focusBorderColor="#FFD800"
            size="md"
            value={searchValue}
            onChange={(event) => {
              setSearchValue(event.target.value);
            }}
          />
          <InputRightElement>
            <Search />
          </InputRightElement>
        </InputGroup>
        {searchValue !== "" && (
          <Button variant="ghost" onClick={() => setSearchValue("")}>
            Cancel
          </Button>
        )}
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Text>Welcome to {directoryData.propertyName}!</Text>
        <Heading>
          <Text as="span" color="#FFD800">
            Scroll
          </Text>{" "}
          to look up a tenant.
        </Heading>
      </Box>
      <List spacing={3} width="100%">
        {directoryData.enableCallManager && searchValue === "" && (
          <ListItem
            id="property-manager"
            onClick={() => onResidentSelected(null)}
          >
            <Box h={16} borderRadius="6px" bgColor="#3B3B3BF8" p={4}>
              <Text fontSize={24} fontWeight="bold">
                Leasing Office
              </Text>
            </Box>
          </ListItem>
        )}
        {directoryData.residents
          .filter(
            (resident) =>
              !searchValue ||
              resident.directory_display_name
                .toLowerCase()
                .includes(searchValue.toLowerCase())
          )
          .map((resident) => (
            <ListItem
              key={resident.id}
              onClick={() => onResidentSelected(resident)}
            >
              <Box h={16} borderRadius="6px" bgColor="#3B3B3BF8" p={4}>
                <Text fontSize={24}>{resident.directory_display_name}</Text>
              </Box>
            </ListItem>
          ))}
      </List>
    </Box>
  );
};

export default ResidentDirectory;
