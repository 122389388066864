import React, { FC, useEffect, useRef } from "react";
import { Box, Button, Heading, Text } from "@chakra-ui/react";

import { Resident } from "src/common/types";
import CallResident from "src/common/icons/CallResident";
import EndCall from "src/common/icons/EndCall";
import { AccessOrDenied } from "src/routes/intercom/AccessOrDenied";
import useCall from "src/hooks/useCall";

interface VisitorCallProps {
  onCallEnded: () => void;
  resident: Resident | null;
}

const VisitorCall: FC<VisitorCallProps> = ({ resident, onCallEnded }) => {
  const remoteAudioRef = useRef<HTMLAudioElement>(null);

  const {
    startCall,
    endCall,
    callState,
    activeCall,
    remoteStream,
    wasOpened,
    error,
  } = useCall();

  const isManager = !resident;
  let callStateText = "Calling...";
  if (callState === "connected") {
    callStateText = `Connected to...`;
  } else if (callState === "destroyed" && !wasOpened) {
    callStateText = `Sorry, your access request was not granted.`;
  } else if (callState === "destroyed" && wasOpened) {
    callStateText =
      "Your access request was granted! Please proceed through the gate.";
  }

  useEffect(() => {
    (async () => {
      await startCall(resident);
    })();
  }, [startCall, resident]);

  useEffect(() => {
    if (remoteStream && remoteAudioRef.current) {
      remoteAudioRef.current.srcObject = remoteStream;
    }
  }, [remoteStream]);

  return (
    <Box
      display="flex"
      flexDir="column"
      marginX="auto"
      justifyContent="center"
      alignItems="center"
      paddingX={4}
      pt={"50%"}
      pb={8}
      gap={2}
    >
      {["connected", "calling"].includes(callState) ? (
        <>
          <audio ref={remoteAudioRef} autoPlay />
          <Text>{callStateText}</Text>
          <Heading size="lg">
            {isManager ? "Leasing Office" : resident.directory_display_name}
          </Heading>
          {callState === "connected" && (
            <Box width="40%" pt={4}>
              <CallResident />
            </Box>
          )}
        </>
      ) : (
        <AccessOrDenied denied={!wasOpened} error={error} />
      )}
      {activeCall && (
        <Button
          sx={{
            mt: 24,
          }}
          size="lg"
          variant="solid"
          bgColor={"#FF453A"}
          leftIcon={<EndCall />}
          onClick={async () => {
            await endCall();
            onCallEnded();
          }}
        >
          End Call
        </Button>
      )}
    </Box>
  );
};

export default VisitorCall;
